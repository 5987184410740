import React, { useEffect } from 'react';
import AdminLayout from '../../Layout/adminlayout';
import { Box, Button, Modal } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import { getApihandler, postApihandler } from '../../Apihandler';
import Swal from 'sweetalert2';
import Paper from "@mui/material/Paper";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10%",
};

export default function AddGroup() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [groupdata, setGroupData] = React.useState([]);
  const [invitedata, setInviteData] = React.useState([]);
  // console.log("groupdata",groupdata);
  const [searchuser, setSearchuser] = React.useState("");
  const [groupname, setGroupname] = React.useState("");
  const [selectedUserIds, setSelectedUserIds] = React.useState([]); // State to store selected user IDs
  // console.log("selectedUserIds", selectedUserIds);
  const [selectedUserNames, setSelectedUserNames] = React.useState([]); // State to store selected user names
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  // useEffect 
  useEffect(() => {
    getGroup();
  }, [])


  useEffect(() => {
    if (searchuser !== "") {
      getSearchdata();
    } else {
      setData([]);
    }
  }, [searchuser]);

  // Function get group
    const getGroup = async () => {
    const userId = localStorage.getItem("userId");
    let res = await getApihandler(`/getGroupsBySenderId/${userId}`);
    // console.log("res get group", res);

    if (res.length !== 0) {
      setGroupData(res);
    }

  }

  // Function to fetch search data
  const getSearchdata = async () => {
    let res = await getApihandler(`/SearchUser/${searchuser}`);
    // console.log("response ", res);
    if (res.message === "Users retrieved successfully") {
      setData(res.users);
    } else {
      setData([]);
    }
  };

  // Function to add selected user ID and name to their respective states
  const handleInvite = (user) => {
    const { _id, name } = user;

    // Check if the ID is already in the array, then add if not present
    if (!selectedUserIds.includes(_id)) {
      setSelectedUserIds([...selectedUserIds, _id]); // Add the new user ID to the array
      setSelectedUserNames([...selectedUserNames, name]); // Add the new user name to the array
    }
  };
   // getMembers by name 
   const getMembers = async(name)=>{
          let res = await getApihandler(`/GetInvitesByGroupName/${name}`);
          // console.log("res===>",res);
          if(res.invites){
            setInviteData(res.invites);   
            handleOpen1();
          }
   }
  // Function to remove selected user by ID
  const handleRemoveUser = (index) => {
    const newUserIds = [...selectedUserIds];
    const newUserNames = [...selectedUserNames];

    // Remove the user at the specified index
    newUserIds.splice(index, 1);
    newUserNames.splice(index, 1);

    setSelectedUserIds(newUserIds); // Update state for IDs
    setSelectedUserNames(newUserNames); // Update state for names
  };


  // Submite data 
  const AddGroup = async () => {
    const userId = localStorage.getItem("userId");
    let req = {
      senderId: userId,
      receiverIds: selectedUserIds,
      groupName: groupname
    }
    let res = await postApihandler("/SendInviteToOtherUser", req);
    handleClose();
    // console.log("res --->", res);
    if (res.message === "Invites processing completed.") {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Group added successfully!",
        confirmButtonText: "OK",
      });
      setData([]);
      setSelectedUserIds([]);
      getGroup();
    } else {
      Swal.fire({
        icon: "error",
        title: "error!",
        text: res.error.response.data.message,
        confirmButtonText: "OK",
      });
      setData([]);
      setSelectedUserIds([]);
    }

  }
  return (
    <AdminLayout>
      <h1>Add Group</h1>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ borderRadius: "30px" }}
        onClick={handleOpen}
      >
        Add Group
      </Button>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form>
            <h3 style={{ textAlign: "center" }}>Add Group</h3>
            <div>
              <label>Group Name:</label>
              <input className="form-control" onChange={(e) => setGroupname(e.target.value)} />
            </div>
            {/* Display selected user names with cross buttons */}
            <div>
              {selectedUserNames.length > 0 && (
                <Box>
                  <h4>Selected Users:</h4>
                  {selectedUserNames.map((name, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <span>{name}</span>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveUser(index)} // Remove user on cross click
                        sx={{ minWidth: "30px", padding: "0" }}
                      >
                        X
                      </Button>
                    </div>
                  ))}
                </Box>
              )}
            </div>

            <div>
              <label>Search User:</label>
              <input
                className="form-control"
                onChange={(e) => setSearchuser(e.target.value)}
              />
              <Box>
                {data.length !== 0
                  ? data.map((val) => {
                    return (
                      <div
                        key={val._id}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          border: "solid 1px #8080806b",
                          borderRadius: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <p>{val.name}</p>
                        <Button onClick={() => handleInvite(val)}>Invite</Button>
                      </div>
                    );
                  })
                  : ""}
              </Box>
            </div>



            <Button

              variant="contained"
              sx={{ marginTop: "20px" }}
              onClick={AddGroup}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Table data */}

      <TableContainer component={Paper} className="mt-5">
        <h3 style={{ textAlign: "center" }}>Group List</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Group Members</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupdata && groupdata.length > 0 ? (
              groupdata.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.groupName}
                  </TableCell>
                  <TableCell >
                   <VisibilityIcon onClick={()=> getMembers(item.groupName)}/>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>


{/* Modal invite member */}
<Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           <div>
           <h3 style={{ textAlign: "center" }}>Group Members</h3>
            <div style={{display:"flex", justifyContent:"space-around" , border:"solid 1px",marginTop:"10px"}}>
              <div><h6>Member</h6></div>
              <div><h6>Status</h6></div>
            </div>
            <div>
              {
                invitedata.length !== 0 ?
                invitedata.map((val)=>{
                  return(
                    <>
                    <div style={{display:"flex", justifyContent:"space-around", border:"solid 1px"}}>
                      <div style={{marginTop:"8px"}}>
                            <p>{val.receiverName}</p>
                      </div>
                      <div style={{marginTop:"8px"}}>
                            <p>{val.status}</p>
                      </div>
                    </div>
                    </>
                  )
                })
                :""
              }
            </div>
           </div>
        </Box>
      </Modal>
    </AdminLayout>
  );
}
