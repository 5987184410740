import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Layout/adminlayout'
import LoanOffer from '../../Components/Loan/loan-offer'
import {  getApihandler, getApihandlerWithdata } from '../../Apihandler';

export default function Loan() {
// useState
  const [loanamount,setLoanamount]= useState();
  // console.log("loanamount",loanamount);
  
// useEffect
useEffect(()=>{
  getOfferLoan();
})

// Functions

const getOfferLoan = async()=>{
  const userId = localStorage.getItem("userId");
 
   let res = await getApihandler(`/getPossibleLoanAmount/${userId}`);
  //  console.log("res->",res);
   if(res.message === "Possible loan amount calculated successfully"){
    setLoanamount(res.possibleLoanAmount);
   }
   
}
  return (
    <AdminLayout>
       <LoanOffer loanamount={loanamount}/>
    </AdminLayout>
  )
}
