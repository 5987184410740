/** @format */

import React, { useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getApihandler, postApihandler } from "../../../Apihandler";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
// ******* modal style **********
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10%",
};
export default function AddMonthlyIncome() {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedMonth, setSelectedMonth] = React.useState('');

  // List of all month names
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Handle the month selection
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // ******* table design ********
  
  
  // ******* integrate api ***********
  const onSubmit = async (value) => {
    const userId = localStorage.getItem("userId");
    const {

      monthlyIncome,
      travel,
      houseRent,
      groceries,
      utilities,
      entertainment,
      others,
    } = value;

    const item = {
      userId: userId,
      monthName:selectedMonth,
      monthlyIncome: Number(monthlyIncome),
      expenditure: {
        travel: Number(travel),
        houseRent: Number(houseRent),
        groceries: Number(groceries),
        utilities: Number(utilities),
        entertainment: Number(entertainment),
        others: Number(others),
      },
    };
    // console.log("item", item);


    const res = await postApihandler("/addMonthlyIncomeAndExpenditure", item);
    // console.log("post api response is ----->", res);

    if (res.message === "Income and expenditure added successfully" || res.message === "Income and expenditure updated successfully") {
      handleClose();
      reset();

      // Display success alert using Swal
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Monthly Income and Expenditure added successfully!",
        confirmButtonText: "OK",
      });
      setTimeout(function () {
        window.location.reload()
      }, 1000);
    } else {
      handleClose();
      Swal.fire({
        icon: "error",
        text: res.error.response.data.message,
        confirmButtonText: "OK",
      });
    }




  };
  // ****** get api ********
  const getIncomeSavings = async () => {
    const userId = localStorage.getItem("userId");

    const res = await getApihandler(`/getUserMonthlyExpenditureAndIncome/${userId}`);
    setData(res.data); // Set the response data directly
    // console.log("get api response is---->", res);
  };

  useEffect(() => {
    getIncomeSavings();
  }, []);

  return (
    <>

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ borderRadius: "30px" }}
        onClick={handleOpen}
      >
        Add Monthly Income & Expenditure
      </Button>
      {/* ****** modal ******** */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 style={{ textAlign: "center" }}>Add Monthly Income</h3>
            <div>
            <label>Monthly Income:</label>
              <select
                id="month-select"
                 className="form-control"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="">Select a month</option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>

            </div>
            <div>
              <label>Monthly Income:</label>
              <input
                type="number"
                className="form-control"
                {...register("monthlyIncome")}
              />
            </div>
            <div>
              <label>Travel:</label>
              <input
                type="number"
                {...register("travel")}
                className="form-control"
              />
            </div>
            <div>
              <label>House Rent:</label>
              <input
                type="number"
                {...register("houseRent")}
                className="form-control"
              />
            </div>
            <div>
              <label>Groceries:</label>
              <input
                type="number"
                {...register("groceries")}
                className="form-control"
              />
            </div>
            <div>
              <label>Utilities:</label>
              <input
                type="number"
                {...register("utilities")}
                className="form-control"
              />
            </div>
            <div>
              <label>Entertainment:</label>
              <input
                type="number"
                {...register("entertainment")}
                className="form-control"
              />
            </div>
            <div>
              <label>Others:</label>
              <input
                type="number"
                {...register("others")}
                className="form-control"
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      {/* ***** table ******* */}
      <TableContainer component={Paper} className="mt-5">
        <h3 style={{ textAlign: "center" }}>Monthly Income</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Month Name</TableCell>
              <TableCell>Monthly Income</TableCell>
              <TableCell>Entertainment</TableCell>
              <TableCell>Groceries</TableCell>
              <TableCell>House Rent</TableCell>

              <TableCell>Travel</TableCell>
              <TableCell>Utilities</TableCell>
              <TableCell>Others</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.monthName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.monthlyIncome}
                  </TableCell>
                  <TableCell>{item.expenditure.entertainment}</TableCell>
                  <TableCell>{item.expenditure.groceries}</TableCell>
                  <TableCell>{item.expenditure.houseRent}</TableCell>

                  <TableCell>{item.expenditure.travel}</TableCell>
                  <TableCell>{item.expenditure.utilities}</TableCell>
                  <TableCell>{item.expenditure.others}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
}
