import * as React from 'react';
import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';

export default function YearlySaving({savingdata}) {
  
  // console.log("savingdata",savingdata);
  

  const size = {
   
    height: 254,
  };


  const data = savingdata.yearlyIncome ? [
    { value: savingdata.savings , label: "Savings" },
    { value: savingdata.totalExpenditure, label: "Total Expenditure" },
    
  ]:[
    { value:0 , label: "Savings" },
    { value: 0, label: "Total Expenditure" },
   
  ]
 
  return (
    <Box
    sx={{
      
    
      // padding: "30px",
      paddingTop: "20px",
      paddingLeft: "20px",
    }}
  >
    <h6 style={{ fontWeight: "600" }}>Expenditure / Saving</h6>
    <PieChart
      series={[{ data, innerRadius: 80 }]}
      {...size}
    ></PieChart>
  </Box>
  );
}
