import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AdminLayout from "../../Layout/adminlayout";
import { getApihandler } from "../../Apihandler";
import AddMonthlyIncome from "../../Components/Income/Monthly/monthly";
import MonthlySaving from "../../Components/Income/Monthly/monthlysaving";
import SavingStatus from "../../Components/Income/Monthly/savingstatus";


export default function MonthlyIncome() {
  const [savingdata, setSavingdata] = useState({})

  // useEffect
  useEffect(() => {
    getWeeklySaving();
  }, [])

  const getWeeklySaving = async () => {
    const userId = localStorage.getItem("userId");
    const res = await getApihandler(`/getMonthlySavings/${userId}`);
    // console.log("res->", res);
    if (res) {
      setSavingdata(res)
    }


  }
  return (
    <AdminLayout>
      <AddMonthlyIncome />
      <Grid container sx={{ marginTop: "30px" }}>
        <Grid xs={12} lg={6} md={6} sx={{}}>
          <MonthlySaving savingdata={savingdata}/>
        </Grid>
        <Grid xs={12} lg={6} md={6}>

          <SavingStatus savingdata={savingdata} />
        </Grid>
      </Grid>

    </AdminLayout>
  );
}
