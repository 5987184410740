/** @format */

import { Typography } from "@mui/material";
import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { postApihandler } from "../../Apihandler";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import fincancemanagerimg from "../../Images/financemanagerimg.png";

export default function Login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const LoginSubmit = async (value) => {
    // console.log("value is --->", value);
    const res = await postApihandler("/UserLogin", value);
    // console.log("res is --->", res);                                        

    
    if (res.status === 200) {
      localStorage.setItem("accessToken", res.accessToken);
      const userId = res.data._id;
    localStorage.setItem("userData", res.data.name);
    localStorage.setItem("userId", userId);
   
      Swal.fire({
        icon: "success",
        title: "Successfully login",
        showConfirmButton: false,
        timer: 2000,
      });
    
      navigate("/dashboard");
    } else {
      
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  return (
    <>
      <section style={{ padding: "50px 0" }}>
        <Container>
          <Row>
            <Col md={6}>
              <Typography variant="h4"> Welcome Back</Typography>
              <Typography variant="p">Login to access all your data</Typography>
              <Form className="mt-5" onSubmit={handleSubmit(LoginSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("userEmail")}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    {...register("password")}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%", borderRadius: "40px" }}
                >
                  Sign In
                </Button>
              </Form>
              <Link to="/signup" style={{ textDecoration: "none", color: "black" }}>
                <p className="mt-3 text-center">
                  Do not Have an account{" "}
                  <span style={{ color: "#407BFF", fontWeight: "600" }}>
                    Sign Up{" "}
                  </span>
                </p>
              </Link>
            </Col>
            <Col md={5}>
              <img src={fincancemanagerimg} alt="" width="100%" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
