/** @format */

import React, { useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getApihandler, postApihandler } from "../../../Apihandler";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
// ******* modal style **********
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",  
  boxShadow: 24,
  p: 4,
  borderRadius:"10%",
};
export default function AddYearlyIncome() {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // ******* table design ********
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  // ******* integrate api ***********
  const onSubmit = async (value) => {
    const userId = localStorage.getItem("userId");
    const {
      yearlyIncome,
      travel,
      houseRent,
      groceries,
      utilities,
      entertainment,
      others,
    } = value;

    const item = {
      userId: userId,
      yearlyIncome: Number(yearlyIncome),
      expenditure: {
        travel: Number(travel),
        houseRent: Number(houseRent),
        groceries: Number(groceries),
        utilities: Number(utilities),
        entertainment: Number(entertainment),
        others: Number(others),
      },
    };
  //  console.log("item",item);
   
    
      const res = await postApihandler("/addYearlyIncomeAndExpenditure", item);
      // console.log("post api response is ----->", res);
     
      if(res.message === "Income and expenditure added successfully"|| res.message === "Income and expenditure updated successfully"){
        handleClose();
        reset();
        
        // Display success alert using Swal
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Yearly Income and Expenditure added successfully!",
          confirmButtonText: "OK",
        });
        setTimeout(function () {
          window.location.reload()
      }, 1000);
      }else  {
        handleClose();
        Swal.fire({
          icon: "error",
          text: res.error.response.data.message,
          confirmButtonText: "OK",
        });
      }
     

    
  
  };
  // ****** get api ********
  const getIncomeSavings = async () => {
    const userId = localStorage.getItem("userId");

    const res = await getApihandler(`/getUserYearlyExpenditureAndIncome/${userId}`);
    setData(res.data); // Set the response data directly
    // console.log("get api response is---->", res);
  };

  useEffect(() => {
    getIncomeSavings();
  }, []);

  return (
    <>
     
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ borderRadius: "30px" }}
          onClick={handleOpen}
        >
          Add Yearly Income & Expenditure
        </Button> 
        {/* ****** modal ******** */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(onSubmit)}>
             <h3 style={{textAlign:"center"}}>Add Yearly Income</h3>
              <div>
                <label>Yearly Income:</label>
                <input
                  type="number"
                  className="form-control"
                  {...register("yearlyIncome")}
                />
              </div>
              <div>
                <label>Travel:</label>
                <input
                  type="number"
                  {...register("travel")}
                  className="form-control"
                />
              </div>
              <div>
                <label>House Rent:</label>
                <input
                  type="number"
                  {...register("houseRent")}
                  className="form-control"
                />
              </div>
              <div>
                <label>Groceries:</label>
                <input
                  type="number"
                  {...register("groceries")}
                  className="form-control"
                />
              </div>
              <div>
                <label>Utilities:</label>
                <input
                  type="number"
                  {...register("utilities")}
                  className="form-control"
                />
              </div>
              <div>
                <label>Entertainment:</label>
                <input
                  type="number"
                  {...register("entertainment")}
                  className="form-control"
                />
              </div>
              <div>
                <label>Others:</label>
                <input
                  type="number"
                  {...register("others")}
                  className="form-control"
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: "20px" }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
        {/* ***** table ******* */}
        <TableContainer component={Paper} className="mt-5">
          <h3 style={{textAlign:"center"}}>Yearly Income</h3>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Yearly Income</TableCell>
                <TableCell>Entertainment</TableCell>
                <TableCell>Groceries</TableCell>
                <TableCell>House Rent</TableCell>

                <TableCell>Travel</TableCell>
                <TableCell>Utilities</TableCell>
                <TableCell>Others</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.yearlyIncome}
                    </TableCell>
                    <TableCell>{item.expenditure.entertainment}</TableCell>
                    <TableCell>{item.expenditure.groceries}</TableCell>
                    <TableCell>{item.expenditure.houseRent}</TableCell>

                    <TableCell>{item.expenditure.travel}</TableCell>
                    <TableCell>{item.expenditure.utilities}</TableCell>
                    <TableCell>{item.expenditure.others}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      
    </>
  );
}
