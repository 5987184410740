

import axios from "axios";
// export const serverUrl = "http://localhost:80/api";
export const serverUrl = "http://finance-manager.co.in/api";
// export const serverUrl = "http://192.168.1.6/api";
// export const serverUrl = "http://192.168.1.5:80/api";https://d9df-2401-4900-1c19-f686-6159-1989-e46f-8be8.ngrok-free.app/

// export const serverUrl = "https://d9df-2401-4900-1c19-f686-6159-1989-e46f-8be8.ngrok-free.app/api";

export const getApihandler = async (endPoint) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const getres = await axios.get(serverUrl + endPoint, {
      headers: {
        "x-access-token": accessToken,
      },
    });

    return getres.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};
export const getApihandlerWithdata = async (endPoint,value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const getres = await axios.get(serverUrl + endPoint,value, {
      headers: {
        "x-access-token": accessToken,
      },
    });

    return getres.data;
  } catch (error) {
    return { message: error.message, status: 400 };
  }
};
export const postApihandler = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const postRes = await axios.post(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    // console.log("apipost=>", postRes);
    return postRes.data;
  } catch (error) {
    // console.log("error is - ", error);
    return { error };
  }
};
export const putApihandler = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const res = await axios.put(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    return res.data;
  } catch (error) {
    return { error };
  }
};
export const postApihandlerAccessToken = async (endPoint, value) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const res = await axios.post(serverUrl + endPoint, value, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    return res.data;
  } catch (error) {
    return { error };
  }
};
export const deleteApiihandler = async (endPoint, id) => {
  let accessToken = localStorage.getItem("accessToken");
  try {
    const deleteRes = await axios.delete(serverUrl + endPoint + id, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    // console.log("apidelete=>", deleteRes);
    return deleteRes.data;
  } catch (error) {
    return { message: error.message, status: 403 };
  }
};
export const deleteParamsApiihandler = async (endPoint) => {
  // console.log("endPoint==>", endPoint);
  let accessToken = localStorage.getItem("accessToken");
  try {
    const deleteRes = await axios.delete(
      serverUrl + endPoint
      //   , {
      //   headers: {
      //     "x-access-token": accessToken,
      //   },
      // }
    );
    // console.log("apidelete=>", deleteRes);
    return deleteRes.data;
  } catch (error) {
    return { error };
  }
};
