import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AdminLayout from "../../Layout/adminlayout";
import { getApihandler } from "../../Apihandler";
import AddYearlyIncome from "../../Components/Income/Yearly/yearlyI";
import SavingStatus from "../../Components/Income/Yearly/savingstatus";
import YearlySaving from "../../Components/Income/Yearly/yearlysaving";



export default function YearlyIncome() {
  const [savingdata, setSavingdata] = useState({})

  // useEffect
  useEffect(() => {
    getWeeklySaving();
  }, [])

  const getWeeklySaving = async () => {
    const userId = localStorage.getItem("userId");
    const res = await getApihandler(`/getYearlySavings/${userId}`);
    // console.log("res->", res);
    if (res) {
      setSavingdata(res)
    }


  }
  return (
    <AdminLayout>
      <AddYearlyIncome />
      <Grid container sx={{ marginTop: "30px" }}>
        <Grid xs={12} lg={6} md={6} >
          <YearlySaving savingdata={savingdata}/>
        </Grid>
        <Grid xs={12} lg={6} md={6}>

          <SavingStatus savingdata={savingdata} />
        </Grid>
      </Grid>

    </AdminLayout>
  );
}
