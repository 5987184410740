import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Layout/adminlayout'
import { getApihandler, putApihandler } from '../../Apihandler';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
import Paper from "@mui/material/Paper";
import Swal from 'sweetalert2';
export default function BudgetReq() {

    // useState
    const [data,setData] = useState([]);

    // useEffect 
    useEffect(()=>{
          getBudgetReq();
    },[])

    // functions get invite list

    const getBudgetReq = async()=>{
        const userId = localStorage.getItem("userId");
        const res = await getApihandler(`/getBudgetsByParticipants/${userId}`);
        // console.log("get req ==>",res);
           if(res.budgets){
               setData(res.budgets);
           }
    }

   
  return (
    <AdminLayout>
        <h1>Budget Request</h1>
     {/* Table data */}

      <TableContainer component={Paper} className="mt-5">
        <h3 style={{ textAlign: "center" }}>Budget List</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Budget Name</TableCell>
              <TableCell>Budget Amount</TableCell>
              <TableCell>Share By</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.name}
                  </TableCell>
                  <TableCell >
                  {item.totalBudget}
                  </TableCell>
                  <TableCell >
                  {item.creator.name}
                  </TableCell>
                  
                
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  )
}
