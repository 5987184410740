import { Card, Grid } from '@mui/material'
import React from 'react'

export default function SavingStatus({savingdata}) {


  return (
    <div>
    <Card sx={{padding:"40px"}}>
        <h1 style={{fontSize:"5rem",textAlign:"center"}}>
           {savingdata.savingsPercentage?savingdata.savingsPercentage:"0%"}
        </h1>
        <h4 style={{fontSize:"20px" ,textAlign:"center"}}>Saving percentage</h4>
        <Grid container style={{marginTop:"30px"}}>
          <Grid lg={4} md={4} xs={4}>
             <div style={{textAlign:"center"}}>
              <h4>{savingdata.monthlyIncome ? savingdata.monthlyIncome : 0}</h4>
              <h6>Monthly Income</h6>
             </div>
          </Grid>
          <Grid lg={4} md={4} xs={4}>
          <div style={{textAlign:"center"}}>
              <h4>{savingdata.totalExpenditure?savingdata.totalExpenditure:0}</h4>
              <h6>Total Expenditure</h6>
             </div>  
          </Grid>
          <Grid lg={4} md={4} xs={4}>
          <div style={{textAlign:"center"}}>
              <h4>{savingdata.savings?savingdata.savings:0}</h4>
              <h6>Saving</h6>
             </div>
          </Grid>
        </Grid>
    </Card>
    </div>
  )
}
