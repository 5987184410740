import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Layout/adminlayout'
import { getApihandler } from '../../Apihandler';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
export default function LoanList() {

  // useState
  const [data,setData] = useState();

  // useEffect
  useEffect(()=>{
    getLoandetails();
  },[])

  // functions

  const getLoandetails = async()=>{
    const userId = localStorage.getItem("userId");
      let res = await getApihandler(`/getLoanDetailsByUserId/${userId}`);
      // console.log("res-->",res);
      if(res.message === "Loan details fetched successfully"){
        setData(res.loans)
      }else{
        setData();
      }
  }
  return (
    <AdminLayout><h1>Loan Details</h1>
    
    <TableContainer component={Paper} className="mt-5">
          <h3 style={{textAlign:"center"}}></h3>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>LoanAmount</TableCell>
                <TableCell>Interest Rate</TableCell>
                <TableCell>Tenure In Months</TableCell>
                <TableCell>EMI</TableCell>

                <TableCell>Total Paid Amount</TableCell>
                <TableCell>Remaining Amount</TableCell>
                <TableCell>Amount With Interest</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? (
             
                  <TableRow >
                    <TableCell component="th" scope="row">
                      {data.requestLoanAmount}
                    </TableCell>
                    <TableCell>{data.interestRate}</TableCell>
                    <TableCell>{data.tenureInMonths}</TableCell>
                    <TableCell>{data.emi}</TableCell>

                    <TableCell>{data.totalPaidAmount}</TableCell>
                    <TableCell>{data.remainingAmount}</TableCell>
                    <TableCell>{data.totalPayAmountWithInterest}</TableCell>
                  </TableRow>
              
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </AdminLayout>
  )
}
