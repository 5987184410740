/** @format */

// /** @format */

import React, { useEffect } from "react";
import AdminLayout from "../../Layout/adminlayout";
import { Box, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
// import { generateToken } from "../../firebase";
import { getApihandler } from "../../Apihandler";

export default function Dashboard() {
  const [dataIn, setData] = React.useState([]);
  const [savingdata, setSavingData] = React.useState([]);
  // const [datalastMonth, setDatalastMonth] = React.useState();
  const [expenddata, setExpenddata] = React.useState({})
  const [avgbudgetdata, setAvgbudgetdata] = React.useState({})
  // console.log("expenddata",expenddata);
  
  const [userName, setUserName] = React.useState("");

  // useEffect
  useEffect(() => {
    getAvgBudget()
    getallMontSaving()
    // generateToken()
    getMonthlyIncome();
    getExpenditure();

  }, [])

  const sortedData = dataIn.sort((a, b) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    return months.indexOf(a.monthName) - months.indexOf(b.monthName);
  });

  // Extract month names and income
  const months = sortedData.map(item => item.monthName);
  const income = sortedData.map(item => item.monthlyIncome);

  // function 

  const getAvgBudget = async()=>{
    const userId = localStorage.getItem("userId");
        const res = await getApihandler(`/getAvgBudgetForExpenditure/${userId}`)
         if(res.message === "Average Budget calculated from the most recent 4 entries"){
          setAvgbudgetdata(res)
         }
  }
  const getExpenditure = async () => {
    const userId = localStorage.getItem("userId");
    const res = await getApihandler(`/getMonthlySavings/${userId}`);
    // console.log("res->", res);
    if (res) {
      setExpenddata(res.expenditureBreakdown);
    }
  }

  
  const getallMontSaving = async()=>{
    const userId = localStorage.getItem("userId");
    const res = await getApihandler(`/getAllMonthSavings/${userId}`);
    // console.log("res==>",res);
    if(res.data){
      setSavingData(res.data);
    }
    
  }


  const getMonthlyIncome = async () => {
    const username = localStorage.getItem("userData");
    setUserName(username);
    const userId = localStorage.getItem("userId");

    const res = await getApihandler(`/getUserMonthlyExpenditureAndIncome/${userId}`);
    // console.log("get api response is---->", res);
    if(res){
      setData(res.data); // Set the response data directly
    }  
  }
  // ****** pie chart style start ********

  
  
  const data = expenddata !== undefined ? [
    { value: expenddata.houseRent , label: "House Rent" },
    { value: expenddata.entertainment, label: "Entertainment" },
    { value: expenddata.groceries, label: "Groceries" },
    { value: expenddata.travel, label: "Travel" },
    { value: expenddata.utilities, label: "Utilities" },
    { value: expenddata.others, label: "Others" },
  ]:[
    { value:0 , label: "House Rent" },
    { value: 0, label: "Entertainment" },
    { value: 0, label: "Groceries" },
    { value: 0, label: "Travel" },
    { value: 0, label: "Utilities" },
    { value: 0, label: "Others" },
  ]


  const size = {
    // width: 600,
    height: 250,
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  // ******** pie chart style end ************
  return (
    <div>
      <AdminLayout>
        <Typography
          variant="h5"
          noWrap
          sx={{ marginBottom: 2, fontWeight: "500", color: "black" }}
        >
          Welcome back {userName}
        </Typography>{" "}
        <br />
        <Typography variant="p " sx={{ marginBottom: 2, color: "black" }}>
          It is the best time to manage your finance
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div className="d-flex">
            {/* <div
              style={{
                boxShadow: "0px 0px 2px 1px #00000040",
                padding: "10px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            >
              <CalendarMonthIcon
                sx={{
                  color: "#5a5a5a",
                }}
              />
            </div> */}
            {/* <div
              style={{
                boxShadow: "0px 0px 2px 1px #00000040",
                borderRadius: "30px",
                width: "130px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h6 style={{ fontWeight: "600" }}>This month</h6>
            </div> */}
          </div>
          {/* <div> 
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "#1877F2", borderRadius: "30px" }}
            >
              Add new widget
            </Button>
          </div> */}
        </div>
        <Container>
          <Row>
          <Col md={6} className="mt-5">
          <Box
                sx={{
                  boxShadow: "0px 0px 2px 1px #00000040",
                  borderRadius: "30px",
                  // width: "270px",
                  padding: "25px",
                }}
              >
                <div className="d-flex justify-content-end me-5 mt-4">
                
                </div>
                <h5 style={{ fontWeight: "600", marginTop: "-15px" }}>
                Average Budget Amount
                </h5>

                <h3 style={{ fontWeight: "500" }}>
                  $ {avgbudgetdata.avgBudget ?avgbudgetdata.avgBudget:0}{" "}
                  
                </h3>
                
              </Box>
          </Col>
          </Row>
          <Row>
            {savingdata.length !== 0?
            savingdata.slice(0, 4).map((val)=>{
              return(
                <>
                <Col md={3} className="mt-5">
              <Box
                sx={{
                  boxShadow: "0px 0px 2px 1px #00000040",
                  borderRadius: "30px",
                  // width: "270px",
                  padding: "25px",
                }}
              >
                <div className="d-flex justify-content-end me-5 mt-4">
                  {/* <div
                    style={{
                      boxShadow: "0px 0px 2px 1px #00000040",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                   
                  </div> */}
                </div>
                <h5 style={{ fontWeight: "600", marginTop: "-15px" }}>
                  Total Amount
                </h5>

                <h3 style={{ fontWeight: "500" }}>
                  $ {val.income}{" "}
                  
                </h3>
                <div className="d-flex">
                  <h6
                    style={{
                      backgroundColor: "#CDF3CF",
                      borderRadius: "12px",
                      width: "125px",
                      height: "27px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  >
                   <span>Saving</span> &nbsp; {val.savingsPercent}%
                  </h6>

                  <p style={{ color: "#5a5a5a" }}>Vs {val.month}</p>
                </div>
              </Box>
            </Col>
                </>
              )
            })
            :""}
            
           
          </Row>
          <Row className="mt-5">
              <Col md={7}>
                <Box
                  sx={{
                    boxShadow: "0px 0px 2px 1px #00000040",
                    borderRadius: "30px",
                    padding: "20px"
                  }}
                >
                  <BarChart
                    xAxis={[
                      {
                        scaleType: "band",
                        data: months,  // Use extracted month names
                      },
                    ]}
                    series={[
                      { data: income },  // Use extracted income
                    ]}
                    width={300}
                    height={400}
                    sx={{ marginLeft: "40px" }}
                  />
                </Box>
              </Col>

              <Col md={5}>
                <Box
                  sx={{
                    boxShadow: "0px 0px 2px 1px #00000040",
                    borderRadius: "30px",
                    // padding: "30px",
                    paddingTop: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <h6 style={{ fontWeight: "600" }}>Expenditure</h6>
                  <PieChart
                    series={[{ data, innerRadius: 80 }]}
                    {...size}
                  ></PieChart>
                </Box>
              </Col>
            </Row>
        </Container>
      </AdminLayout>
    </div>
  );
}
