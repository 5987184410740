import { Button } from '@mui/material'
import React, { useState } from 'react'
import { postApihandler } from '../../Apihandler';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function LoanOffer({ loanamount }) {
  // useNavigation
  const history = useNavigate();
   // useState
  const [amount,setAmount] = useState();
  // console.log("amount",amount);
  
 // funtion

 const applyLoan = async()=>{
  const userId = localStorage.getItem("userId");
  let data = {
    userId:userId,
    loanAmount:amount
  }
     let res = await postApihandler("/requestForLoanAmount",data)
    //  console.log("res==>",res);
     if(res.message === "Loan approved and saved successfully"){
      setAmount();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Loan approved and saved successfully",
        confirmButtonText: "OK",
      });
      setTimeout(function () {
      history("/loan-list")
    }, 1500);
     }else{
      Swal.fire({
        icon: "error",
        text: res.error.response.data.message,
        confirmButtonText: "OK",
      });
     }
 }
  return (
    <>
      <div>
        <h1>Loan Offer</h1>
        <h1 style={{ textAlign: "center", fontSize: "5rem", color: "#666666" }}>${loanamount ? loanamount : 0}</h1>
        <p style={{ fontSize: "20px", textAlign: "center" }}>Your Possible loan amount</p>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h1>Apply For Loan</h1>
        <div>
          <div style={{ display: "flex", justifyContent: "center" ,marginTop:"4rem"}}>
            <div >
              <h6 style={{ fontSize: "20px " }}>Request Loan Amount</h6>
              <input onChange={(e)=>setAmount(e.target.value)} style={{ fontSize: "30px", border: "solid 3px #666666" }} placeholder='Enter Amount' />
              <div style={{textAlign:"center",marginTop:"30px"}}>
              <Button onClick={applyLoan} variant="contained"  style={{textTransform:"capitalize",paddingLeft:"30px",paddingRight:"30px"}}>Apply</Button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}
