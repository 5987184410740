import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import ReportIcon from "@mui/icons-material/Report";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Collapse, List } from "@mui/material";
import AddCardIcon from '@mui/icons-material/AddCard';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import LogoutIcon from '@mui/icons-material/Logout';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
export default function ListItemSidebar() {

  const history = useNavigate()
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickLoan = () => {
    setOpen1(!open1);
  };

  const handleClickGroup = () => {
    setOpen2(!open2);
  };
  const handleClickBudget = () => {
    setOpen3(!open3);
  };
  
   const logout = async()=>{
    
    localStorage.removeItem("userData");
    localStorage.removeItem("userId");
    history("/login")
   }
  
 
  return (

  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon sx={{ color: "black" }} />
      </ListItemIcon>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <ListItemText sx={{ color: "black" }} primary="Dashboard" />
      </Link>
    </ListItemButton>
    
   

    <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <PeopleIcon sx={{ color: "black" }} />
        </ListItemIcon>
        <ListItemText primary="Income" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/weekly-income" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddCardIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Weekly Income" />
            </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/monthly-income" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddCardIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Monthly Income" />
            </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/yearly-income" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddCardIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Yearly Income" />
            </ListItemButton>
          </Link>
        </List>
        
       
      </Collapse>
      
      <ListItemButton onClick={handleClickLoan}>
        <ListItemIcon>
          <AssuredWorkloadIcon sx={{ color: "black" }} />
        </ListItemIcon>
        <ListItemText primary="Loan" />
        {open1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/loan-offer" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <RequestQuoteIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Loan Offer" />
            </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/loan-list" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <RequestQuoteIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Loans" />
            </ListItemButton>
          </Link>
        </List>
       
        
       
      </Collapse>


      <ListItemButton onClick={handleClickBudget}>
        <ListItemIcon>
          <AssuredWorkloadIcon sx={{ color: "black" }} />
        </ListItemIcon>
        <ListItemText primary="Budget" />
        {open3 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open3} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/add-budget" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PointOfSaleIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Create Budget" />
            </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/budget-req" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InsertInvitationIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Invite Budget" />
            </ListItemButton>
          </Link>
        </List>
       
        
       
      </Collapse>

      <ListItemButton onClick={handleClickGroup}>
        <ListItemIcon>
          <GroupsIcon sx={{ color: "black" }} />
        </ListItemIcon>
        <ListItemText primary="Group" />
        {open2 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/add-group" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GroupAddIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Create Group" />
            </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
          <Link to="/invite-list" style={{textDecoration:"none"}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <InsertInvitationIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText  sx={{color:"black"}} primary="Invite request" />
            </ListItemButton>
          </Link>
        </List>
       
        
       
      </Collapse>
    
      
      
      <ListItemButton>
      <ListItemIcon>
        <AccountBalanceIcon sx={{ color: "black" }} />
      </ListItemIcon>
      <Link to="/convert-currency" style={{ textDecoration: "none" }}>
        <ListItemText sx={{ color: "black" }} primary="Convert Currency" />
      </Link>
    </ListItemButton>
      
    {/* <ListItemButton>
      <ListItemIcon>
        <AttachMoneyIcon sx={{ color: "White" }} />
      </ListItemIcon>
      <Link to="/earning">
        {" "}
        <ListItemText sx={{ color: "White" }} primary="Earning" />
      </Link>
    </ListItemButton> */}
    {/* <ListItemButton>
      <ListItemIcon>
        <AccountBalanceIcon sx={{ color: "White" }} />
      </ListItemIcon>
      <Link to="/banking">
        {" "}
        <ListItemText sx={{ color: "White" }} primary="Banking" />
      </Link>
    </ListItemButton> */}
    <ListItemButton onClick={logout}>
      <ListItemIcon>
        <LogoutIcon sx={{ color: "black" }} />
      </ListItemIcon>
      <Link style={{ textDecoration: "none" }}>
        <ListItemText  sx={{ color: "black" }} primary="Logout" />
      </Link>
    </ListItemButton>
  </React.Fragment>
)
}