import "./App.css";
import { Route, Routes } from "react-router-dom";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import MyProfile from "./Pages/Myprofile";
import Dashboard from "./Pages/Dashboard";
import WeeklyIncome from "./Pages/AddIncome/weekly-income";
import MonthlyIncome from "./Pages/AddIncome/monthly-income";
import YearlyIncome from "./Pages/AddIncome/yearly-income";
import Loan from "./Pages/Loan/loan";
import LoanList from "./Pages/Loan/loan-list";
import AddGroup from "./Pages/Group/add-group";
import InviteReq from "./Pages/Group/invite-req";
import AddBudget from "./Pages/Budget/add-budget";
import BudgetReq from "./Pages/Budget/budget-req";
import CurrencyConverter from "./Pages/ConvertCurrency";
import Landing from "./Pages/Landing";


function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} ></Route>
          <Route path="/signup" element={<Signup />} ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/weekly-income" element={<WeeklyIncome />} />
          <Route path="/monthly-income" element={<MonthlyIncome />} />
          <Route path="/yearly-income" element={<YearlyIncome />} />
          <Route path="/loan-offer" element={<Loan />} />
          <Route path="/loan-list" element={<LoanList />} />
          <Route path="/add-group" element={<AddGroup />} />
          <Route path="/invite-list" element={<InviteReq />} />
          <Route path="/add-budget" element={<AddBudget />} />
          <Route path="/budget-req" element={<BudgetReq />} />
          <Route path="/convert-currency" element={<CurrencyConverter />} />
        </Routes>
     
    </div>
  );
}

export default App;
