import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Layout/adminlayout'
import { getApihandler, putApihandler } from '../../Apihandler';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
import Paper from "@mui/material/Paper";
import Swal from 'sweetalert2';
export default function InviteReq() {

    // useState
    const [data,setData] = useState([]);

    // useEffect 
    useEffect(()=>{
          getInvitetion();
    },[])

    // functions get invite list

    const getInvitetion = async()=>{
        const userId = localStorage.getItem("userId");
        const res = await getApihandler(`/GetReceiverInvites/${userId}`);
      //  console.log("get req ==>",res);
       
        
           if(res.invites){
               setData(res.invites);
           }
    }

    // function accept requiest 
    const acceptRequiest = async(id)=>{
        let req = {
            status:"Accepted"
        }
        let res = await putApihandler(`/UpdateInviteStatus/${id}`,req);
        // console.log("res=========>",res);
        
        if( res.message === "Invite status updated successfully"){
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Accept request successfully!",
                confirmButtonText: "OK",
              });
              getInvitetion();
        }else{
            Swal.fire({
                icon: "error",
                text: res.error.response.data.message,
                confirmButtonText: "OK",
              });
        }
    }
  return (
    <AdminLayout>
        <h1>Invite Request</h1>
     {/* Table data */}

      <TableContainer component={Paper} className="mt-5">
        <h3 style={{ textAlign: "center" }}>Invite List</h3>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Sender Name</TableCell>
              <TableCell>Action</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.groupName}
                  </TableCell>
                  <TableCell >
                  {item.senderId.name}
                  </TableCell>
                  <TableCell >
                    {
                        item.status !== "Accepted" ?
                        
                        <Button variant="contained" color="success" onClick={()=> acceptRequiest(item._id)} >Accept</Button>
                        :<Button variant="outlined" disabled>
                        Accepted
                      </Button>
                    }
                  
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  )
}
