import React, { useState } from 'react';
import AdminLayout from '../../Layout/adminlayout';
import { postApihandler } from '../../Apihandler';
import { Box, Button } from '@mui/material';

const currencyCodes = [
    { country: "Afghanistan", currency: "Afghani", code: "AFN" },
    { country: "Albania", currency: "Lek", code: "ALL" },
    { country: "Algeria", currency: "Dinar", code: "DZD" },
    { country: "Andorra", currency: "Euro", code: "EUR" },
    { country: "Angola", currency: "Kwanza", code: "AOA" },
    { country: "Antigua and Barbuda", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Argentina", currency: "Peso", code: "ARS" },
    { country: "Armenia", currency: "Dram", code: "AMD" },
    { country: "Australia", currency: "Dollar", code: "AUD" },
    { country: "Austria", currency: "Euro", code: "EUR" },
    { country: "Azerbaijan", currency: "Manat", code: "AZN" },
    { country: "Bahamas", currency: "Dollar", code: "BSD" },
    { country: "Bahrain", currency: "Dinar", code: "BHD" },
    { country: "Bangladesh", currency: "Taka", code: "BDT" },
    { country: "Barbados", currency: "Dollar", code: "BBD" },
    { country: "Belarus", currency: "Ruble", code: "BYN" },
    { country: "Belgium", currency: "Euro", code: "EUR" },
    { country: "Belize", currency: "Dollar", code: "BZD" },
    { country: "Benin", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Bhutan", currency: "Ngultrum", code: "BTN" },
    { country: "Bolivia", currency: "Boliviano", code: "BOB" },
    { country: "Bosnia and Herzegovina", currency: "Convertible Mark", code: "BAM" },
    { country: "Botswana", currency: "Pula", code: "BWP" },
    { country: "Brazil", currency: "Real", code: "BRL" },
    { country: "Brunei", currency: "Dollar", code: "BND" },
    { country: "Bulgaria", currency: "Lev", code: "BGN" },
    { country: "Burkina Faso", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Burundi", currency: "Franc", code: "BIF" },
    { country: "Cabo Verde", currency: "Escudo", code: "CVE" },
    { country: "Cambodia", currency: "Riel", code: "KHR" },
    { country: "Cameroon", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Canada", currency: "Dollar", code: "CAD" },
    { country: "Central African Republic", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Chad", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Chile", currency: "Peso", code: "CLP" },
    { country: "China", currency: "Yuan Renminbi", code: "CNY" },
    { country: "Colombia", currency: "Peso", code: "COP" },
    { country: "Comoros", currency: "Franc", code: "KMF" },
    { country: "Congo (Congo-Brazzaville)", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Congo (Congo-Kinshasa)", currency: "Congolese Franc", code: "CDF" },
    { country: "Costa Rica", currency: "Colon", code: "CRC" },
    { country: "Croatia", currency: "Euro", code: "EUR" },
    { country: "Cuba", currency: "Peso", code: "CUP" },
    { country: "Cyprus", currency: "Euro", code: "EUR" },
    { country: "Czech Republic", currency: "Koruna", code: "CZK" },
    { country: "Denmark", currency: "Krone", code: "DKK" },
    { country: "Djibouti", currency: "Franc", code: "DJF" },
    { country: "Dominica", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Dominican Republic", currency: "Peso", code: "DOP" },
    { country: "East Timor", currency: "Dollar", code: "USD" },
    { country: "Ecuador", currency: "Dollar", code: "USD" },
    { country: "Egypt", currency: "Pound", code: "EGP" },
    { country: "El Salvador", currency: "Dollar", code: "USD" },
    { country: "Equatorial Guinea", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Eritrea", currency: "Nakfa", code: "ERN" },
    { country: "Estonia", currency: "Euro", code: "EUR" },
    { country: "Eswatini", currency: "Lilangeni", code: "SZL" },
    { country: "Ethiopia", currency: "Birr", code: "ETB" },
    { country: "Fiji", currency: "Dollar", code: "FJD" },
    { country: "Finland", currency: "Euro", code: "EUR" },
    { country: "France", currency: "Euro", code: "EUR" },
    { country: "Gabon", currency: "CFA Franc BEAC", code: "XAF" },
    { country: "Gambia", currency: "Dalasi", code: "GMD" },
    { country: "Georgia", currency: "Lari", code: "GEL" },
    { country: "Germany", currency: "Euro", code: "EUR" },
    { country: "Ghana", currency: "Cedi", code: "GHS" },
    { country: "Greece", currency: "Euro", code: "EUR" },
    { country: "Grenada", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Guatemala", currency: "Quetzal", code: "GTQ" },
    { country: "Guinea", currency: "Franc", code: "GNF" },
    { country: "Guinea-Bissau", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Guyana", currency: "Dollar", code: "GYD" },
    { country: "Haiti", currency: "Gourde", code: "HTG" },
    { country: "Honduras", currency: "Lempira", code: "HNL" },
    { country: "Hungary", currency: "Forint", code: "HUF" },
    { country: "Iceland", currency: "Krona", code: "ISK" },
    { country: "India", currency: "Rupee", code: "INR" },
    { country: "Indonesia", currency: "Rupiah", code: "IDR" },
    { country: "Iran", currency: "Rial", code: "IRR" },
    { country: "Iraq", currency: "Dinar", code: "IQD" },
    { country: "Ireland", currency: "Euro", code: "EUR" },
    { country: "Israel", currency: "New Shekel", code: "ILS" },
    { country: "Italy", currency: "Euro", code: "EUR" },
    { country: "Jamaica", currency: "Dollar", code: "JMD" },
    { country: "Japan", currency: "Yen", code: "JPY" },
    { country: "Jordan", currency: "Dinar", code: "JOD" },
    { country: "Kazakhstan", currency: "Tenge", code: "KZT" },
    { country: "Kenya", currency: "Shilling", code: "KES" },
    { country: "Kiribati", currency: "Dollar", code: "AUD" },
    { country: "Kuwait", currency: "Dinar", code: "KWD" },
    { country: "Kyrgyzstan", currency: "Som", code: "KGS" },
    { country: "Laos", currency: "Kip", code: "LAK" },
    { country: "Latvia", currency: "Euro", code: "EUR" },
    { country: "Lebanon", currency: "Pound", code: "LBP" },
    { country: "Lesotho", currency: "Loti", code: "LSL" },
    { country: "Liberia", currency: "Dollar", code: "LRD" },
    { country: "Libya", currency: "Dinar", code: "LYD" },
    { country: "Liechtenstein", currency: "Franc", code: "CHF" },
    { country: "Lithuania", currency: "Euro", code: "EUR" },
    { country: "Luxembourg", currency: "Euro", code: "EUR" },
    { country: "Madagascar", currency: "Ariary", code: "MGA" },
    { country: "Malawi", currency: "Kwacha", code: "MWK" },
    { country: "Malaysia", currency: "Ringgit", code: "MYR" },
    { country: "Maldives", currency: "Rufiyaa", code: "MVR" },
    { country: "Mali", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Malta", currency: "Euro", code: "EUR" },
    { country: "Marshall Islands", currency: "Dollar", code: "USD" },
    { country: "Mauritania", currency: "Ouguiya", code: "MRU" },
    { country: "Mauritius", currency: "Rupee", code: "MUR" },
    { country: "Mexico", currency: "Peso", code: "MXN" },
    { country: "Micronesia", currency: "Dollar", code: "USD" },
    { country: "Moldova", currency: "Leu", code: "MDL" },
    { country: "Monaco", currency: "Euro", code: "EUR" },
    { country: "Mongolia", currency: "Tugrik", code: "MNT" },
    { country: "Montenegro", currency: "Euro", code: "EUR" },
    { country: "Morocco", currency: "Dirham", code: "MAD" },
    { country: "Mozambique", currency: "Metical", code: "MZN" },
    { country: "Myanmar", currency: "Kyat", code: "MMK" },
    { country: "Namibia", currency: "Dollar", code: "NAD" },
    { country: "Nauru", currency: "Dollar", code: "AUD" },
    { country: "Nepal", currency: "Rupee", code: "NPR" },
    { country: "Netherlands", currency: "Euro", code: "EUR" },
    { country: "New Zealand", currency: "Dollar", code: "NZD" },
    { country: "Nicaragua", currency: "Cordoba", code: "NIO" },
    { country: "Niger", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Nigeria", currency: "Naira", code: "NGN" },
    { country: "North Korea", currency: "Won", code: "KPW" },
    { country: "North Macedonia", currency: "Denar", code: "MKD" },
    { country: "Norway", currency: "Krone", code: "NOK" },
    { country: "Oman", currency: "Rial", code: "OMR" },
    { country: "Pakistan", currency: "Rupee", code: "PKR" },
    { country: "Palau", currency: "Dollar", code: "USD" },
    { country: "Panama", currency: "Balboa", code: "PAB" },
    { country: "Papua New Guinea", currency: "Kina", code: "PGK" },
    { country: "Paraguay", currency: "Guarani", code: "PYG" },
    { country: "Peru", currency: "Sol", code: "PEN" },
    { country: "Philippines", currency: "Peso", code: "PHP" },
    { country: "Poland", currency: "Zloty", code: "PLN" },
    { country: "Portugal", currency: "Euro", code: "EUR" },
    { country: "Qatar", currency: "Riyal", code: "QAR" },
    { country: "Romania", currency: "Leu", code: "RON" },
    { country: "Russia", currency: "Ruble", code: "RUB" },
    { country: "Rwanda", currency: "Franc", code: "RWF" },
    { country: "Saint Kitts and Nevis", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Saint Lucia", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Saint Vincent and the Grenadines", currency: "East Caribbean Dollar", code: "XCD" },
    { country: "Samoa", currency: "Tala", code: "WST" },
    { country: "San Marino", currency: "Euro", code: "EUR" },
    { country: "Sao Tome and Principe", currency: "Dobra", code: "STN" },
    { country: "Saudi Arabia", currency: "Riyal", code: "SAR" },
    { country: "Senegal", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Serbia", currency: "Dinar", code: "RSD" },
    { country: "Seychelles", currency: "Rupee", code: "SCR" },
    { country: "Sierra Leone", currency: "Leone", code: "SLL" },
    { country: "Singapore", currency: "Dollar", code: "SGD" },
    { country: "Slovakia", currency: "Euro", code: "EUR" },
    { country: "Slovenia", currency: "Euro", code: "EUR" },
    { country: "Solomon Islands", currency: "Dollar", code: "SBD" },
    { country: "Somalia", currency: "Shilling", code: "SOS" },
    { country: "South Africa", currency: "Rand", code: "ZAR" },
    { country: "South Korea", currency: "Won", code: "KRW" },
    { country: "South Sudan", currency: "Pound", code: "SSP" },
    { country: "Spain", currency: "Euro", code: "EUR" },
    { country: "Sri Lanka", currency: "Rupee", code: "LKR" },
    { country: "Sudan", currency: "Pound", code: "SDG" },
    { country: "Suriname", currency: "Dollar", code: "SRD" },
    { country: "Sweden", currency: "Krona", code: "SEK" },
    { country: "Switzerland", currency: "Franc", code: "CHF" },
    { country: "Syria", currency: "Pound", code: "SYP" },
    { country: "Taiwan", currency: "Dollar", code: "TWD" },
    { country: "Tajikistan", currency: "Somoni", code: "TJS" },
    { country: "Tanzania", currency: "Shilling", code: "TZS" },
    { country: "Thailand", currency: "Baht", code: "THB" },
    { country: "Togo", currency: "CFA Franc BCEAO", code: "XOF" },
    { country: "Tonga", currency: "Pa’anga", code: "TOP" },
    { country: "Trinidad and Tobago", currency: "Dollar", code: "TTD" },
    { country: "Tunisia", currency: "Dinar", code: "TND" },
    { country: "Turkey", currency: "Lira", code: "TRY" },
    { country: "Turkmenistan", currency: "Manat", code: "TMT" },
    { country: "Tuvalu", currency: "Dollar", code: "AUD" },
    { country: "Uganda", currency: "Shilling", code: "UGX" },
    { country: "Ukraine", currency: "Hryvnia", code: "UAH" },
    { country: "United Arab Emirates", currency: "Dirham", code: "AED" },
    { country: "United Kingdom", currency: "Pound", code: "GBP" },
    { country: "United States", currency: "Dollar", code: "USD" },
    { country: "Uruguay", currency: "Peso", code: "UYU" },
    { country: "Uzbekistan", currency: "Som", code: "UZS" },
    { country: "Vanuatu", currency: "Vatu", code: "VUV" },
    { country: "Vatican", currency: "Euro", code: "EUR" },
    { country: "Venezuela", currency: "Bolívar Soberano", code: "VES" },
    { country: "Vietnam", currency: "Dong", code: "VND" },
    { country: "Yemen", currency: "Rial", code: "YER" },
    { country: "Zambia", currency: "Kwacha", code: "ZMW" },
    { country: "Zimbabwe", currency: "Dollar", code: "ZWL" }
]
  
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10%",
  }; 
function CurrencyConverter() {
  const [amount, setAmount] = useState();
  const [fromCurrency, setFromCurrency] = useState("USD");
  const [toCurrency, setToCurrency] = useState("EUR");
  const [convertedAmount, setConvertedAmount] = useState(0);

  // Mock exchange rates for simplicity
  const exchangeRates = {
    USD: { EUR: 0.85, GBP: 0.75, CAD: 1.25, BRL: 5.26 },
    EUR: { USD: 1.18, GBP: 0.88, CAD: 1.47, BRL: 6.18 },
    // Add more exchange rates as needed...
  };

  const handleConvert = async() => {
    let item = {
        fromCurrency:fromCurrency,
        toCurrency:toCurrency,
        amount:Number(amount)
    }
    // console.log("item",item);
    const res = await postApihandler("/currencyConverter",item)
    if(res.convertedAmount){
        setConvertedAmount(res.convertedAmount);
    };
    
    // console.log("response ===>",res);
    
  };

  return (
    <AdminLayout>
      <h1>Currency Converter</h1>
      <Box >
      {/* Amount Input */}
      <input
        type="number"
        className="form-control"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
      />
<br/>
      {/* From Currency Selection */}
      <select
      className="form-control"
        value={fromCurrency}
        onChange={(e) => setFromCurrency(e.target.value)}
      >
        {currencyCodes.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.currency} ({currency.code})
          </option>
        ))}
      </select>
      <br/>

      {/* To Currency Selection */}
      <select
      className="form-control"
        value={toCurrency}
        onChange={(e) => setToCurrency(e.target.value)}
      >
        {currencyCodes.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.currency} ({currency.code})
          </option>
        ))}
      </select>
      <br/>

      {/* Convert Button */}
      <Button
              type="submit"
              variant="contained"
              sx={{ marginTop: "20px" }}
             onClick={handleConvert}>Convert</Button>
<br/>
<br/>

 {/* Result Display */}
 {convertedAmount && (
        <h2>
          {amount} {fromCurrency} = {convertedAmount} {toCurrency}
        </h2>
      )}
    </Box>
    </AdminLayout>
  );
}

export default CurrencyConverter;
