/** @format */

import React, { useEffect, useState } from "react";
import {
  deleteApiihandler,
  getApihandler,
  putApihandler,
} from "../../Apihandler";
import { Button, Modal, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import AdminLayout from "../../Layout/adminlayout";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function MyProfile() {

  const history = useNavigate()
  // ***** modal style ********
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  // ******* update api *********
  const [name, setName] = useState(""); // State to store updated name
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // ****** get profile api ********
  const [data, setData] = useState("");
  const Userprofile = async () => {
    try {
      // Get the userId from localStorage or another source
      const userId = localStorage.getItem("userId");

      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      // Get the access token from localStorage or another source (update if you store it elsewhere)

      // Send the API request with the userId and access token in the header
      const res = await getApihandler(`/getUserById/${userId}`);
      setData(res.data);
      setName(res.data.name);
      setPhoneNumber(res.data.phoneNumber)
      // console.log(" get API response is ---->", res);
      // Handle your response here
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    Userprofile();
  }, []);

  


  const handleUpdate = async (e) => {
    e.preventDefault();
    
    const userId = localStorage.getItem("userId");
    const req = {
      name:name,
      phoneNumber:phoneNumber
    }
    const res = await putApihandler(`/updateUserProfile/${userId}`, req);
    // console.log("update api response is --->", res);
    handleClose()
    if(res.status === 200){
      Swal.fire({
        icon: "success",
        title: "Profile Update Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      localStorage.setItem("userData", res.data.name);
      Userprofile()
    }else{
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        text: res.error.response.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    // alert("Profile updated successfully!");
    // setData(res.data);
  };

  // ****** delete user api ********
  const handleDeleteProfile = async () => {
    const userId = localStorage.getItem("userId");
    const res = await deleteApiihandler("/deleteUser/",userId);
    // console.log("delete api response is ---->", res);
    if(res.status === 200){
      Swal.fire({
        icon: "success",
        title: "Profile deleted Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      history("/login")
    }
  };
  return (
    <AdminLayout>
      <Typography variant="h5" sx={{ textAlign: "center", marginTop: "20px" }}>
        My <span style={{ color: "#407bff", fontWeight: "600" }}>Profile</span>
      </Typography>
      <Container>
        <Box
          sx={{
            boxShadow: "0px 0px 2px 1px #00000040",
            padding: "30px",
            marginTop: "40px",
          }}
        >
          <Row>
            <Col md={6} style={{ fontWeight: "600" }}>
              {" "}
              Name
            </Col>
            <Col md={6}>{data.name}</Col>
            <Col md={6} style={{ fontWeight: "600" }}>
              {" "}
              Email
            </Col>
            <Col md={6}>{data.userEmail}</Col>
            <Col md={6} style={{ fontWeight: "600" }}>
              {" "}
              Phone Number
            </Col>
            <Col md={6}>{data.phoneNumber}</Col>
          </Row>
          <Button
            onClick={handleOpen}
            sx={{
              backgroundColor: "#407bff",
              color: "white",
              marginTop: "30px",
            }}
          >
            Update Profile
          </Button>
          <Button
            onClick={handleDeleteProfile}
            sx={{
              backgroundColor: "#407bff",
              color: "white",
              marginTop: "30px",
              marginLeft: "20px",
            }}
          >
            Delete Profile
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h3>Update Profile</h3>
              <form >
                <div className="mb-3">
                  <label>Name:</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    // Update name state
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Phone:</label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} // Update phoneNumber state
                    className="form-control"
                  />
                </div>
                <Button type="submit" variant="contained" onClick={handleUpdate}>
                  Update Profile
                </Button>
              </form>
            </Box>
          </Modal>
        </Box>
      </Container>
    </AdminLayout>
  );
}
